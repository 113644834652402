import React, { Fragment, useCallback, useState } from 'react';

import { CloseIcon } from 'components/experience/controls/voiceSelector/icons';
import { useRouter } from 'next/router';
import { useSelector } from 'store';
import { getSelectedDailyListeningGoal } from 'store/gamification/selectors';

import { Dialog, Transition } from '@headlessui/react';

import { DailyListeningGoalPicker, SetDailyListeningGoalHint } from './DailyListeningGoalPicker';

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
const removeQueryParam = (param: any, router: any) => {
  const { pathname, query } = router;
  const params = new URLSearchParams(query);
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
};

export const SetDailyListeningGoalModal = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);
  const selectedDailyListeningGoal = useSelector(getSelectedDailyListeningGoal);

  const onClose = useCallback(() => {
    removeQueryParam('setStreakGoal', router);
    setIsOpen(false);
  }, [router, setIsOpen]);

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog
        open={isOpen}
        onClose={() => {
          // returning null here so that the close is only triggered from Close Icon so that it's less sensitive.
        }}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="relative mx-auto max-w-sm rounded-xl bg-white shadow-lg">
            <CloseIcon className="absolute right-4 top-4 fill-glass-600 hover:cursor-pointer" onClick={onClose} />
            <div className="px-12 pb-8 pt-8">
              <DailyListeningGoalPicker type="modal" onFinishSettingGoal={() => setTimeout(() => setIsOpen(false), 2500)} />
            </div>
            <div className="w-full rounded-xl">
              <SetDailyListeningGoalHint className="rounded-b-xl" selectedDailyListeningGoal={selectedDailyListeningGoal} />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
};
