import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CheckmarkIcon } from 'assets/icons/checkmark';
import HintIcon from 'assets/icons/hint';
import Button from 'components/elements/Button';
import Loading from 'components/elements/Loading';
import { useTranslation } from 'hooks/useTypedTranslation';
import * as speechify from 'lib/speechify';
import { useDispatch, useSelector, useStore } from 'store';
import { actions as authActions } from 'store/auth';
import { actions as gamificationActions } from 'store/gamification';
import { POSSIBLE_DAILY_LISTENING_GOALS_IN_MINUTES } from 'store/gamification/constants';
import { getSelectedDailyListeningGoal } from 'store/gamification/selectors';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';
import { setCustomAccountSetting } from 'utils/baseAccountSettings';

export type DailyListeningGoalPickerProps = {
  type: 'onboardingStep' | 'modal' | 'settingsPage';
  onFinishSettingGoal?: () => void;
};

enum ButtonState {
  init,
  loading,
  success
}

const DailyListeningGoalOption = ({
  valueInMinutes,
  active,
  onClick,
  disabled,
  // ESLint: 'type' is defined but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type
}: {
  active: boolean; //
  disabled: boolean;
  valueInMinutes: number;
  onClick: $TSFixMe;
  type: DailyListeningGoalPickerProps['type'];
}) => {
  const classNames = useMemo(() => {
    const baseClassNames = [
      'relative', //
      'rounded-lg',
      'border',
      'border-glass-300',
      'text-glass-700',
      'font-medium',
      'py-1',
      'px-2',
      'max-w-[67px]',
      'overflow-hidden',
      'flex-1',
      'disabled:bg-glass-300',
      'disabled:text-glass-500',
      'text-sm'
    ];
    if (active) {
      baseClassNames.push(...['border-electric-350', 'border-2']);
    }
    return baseClassNames.join(' ');
  }, [active]);
  return (
    <button className={classNames} onClick={onClick} disabled={disabled}>
      {active && (
        <div className="absolute right-0 top-0 rounded-sm">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[2px] top-[2px] z-10">
            <path
              d="M3.34446 7.00013C3.57051 7.00013 3.74812 6.91424 3.87083 6.73612L6.87729 2.22247C6.96448 2.09205 7 1.968 7 1.85348C7 1.54494 6.76103 1.31592 6.43811 1.31592C6.21851 1.31592 6.07966 1.39544 5.94403 1.6022L3.33154 5.66098L2.00753 4.06101C1.88482 3.91469 1.74919 3.85107 1.56189 3.85107C1.23574 3.85107 1 4.08009 1 4.39182C1 4.53177 1.04198 4.65265 1.16469 4.78943L2.831 6.76156C2.96986 6.92379 3.13132 7.00013 3.34446 7.00013Z"
              fill="white"
            />
          </svg>
          <svg width="36" height="36" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute -right-0 -top-4">
            <path d="M22 0H0L22 22V0Z" fill="#6B78FC" />
          </svg>
        </div>
      )}
      <span>{`${valueInMinutes} min`}</span>
    </button>
  );
};

export const DailyListeningGoalPicker = ({ type = 'settingsPage', onFinishSettingGoal }: DailyListeningGoalPickerProps): JSX.Element => {
  const selectedDailyListeningGoal = useSelector(getSelectedDailyListeningGoal);
  const [buttonState, setButtonState] = useState<ButtonState>(ButtonState.init);
  const dispatch = useDispatch();
  const store = useStore();
  const setSelectedDailyListeningGoal = useCallback((goal: $TSFixMe) => dispatch(gamificationActions.setSelectedDailyListeningGoal(goal)), [dispatch]);
  const onStreakSelectGenerator = (streakGoalInDays: number) => () => setSelectedDailyListeningGoal(streakGoalInDays);

  const { t } = useTranslation('common');

  const shouldShowTitle = useMemo(() => type !== 'onboardingStep', [type]);
  const shouldShowSubtitle = useMemo(() => type !== 'onboardingStep', [type]);
  const shouldShowHint = useMemo(() => type === 'onboardingStep', [type]);

  const ref = useRef<HTMLDivElement>(null);

  const scrollTo = () => {
    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
  };

  useEffect(() => {
    scrollTo();
  }, []);

  useEffect(() => {
    if (type === 'onboardingStep') {
      const userId = store.getState().auth?.user?.uid;
      userId && speechify.setDailyListeningGoal({ dailyListeningGoalInMinutes: 2, userId });
      setCustomAccountSetting('hasSetDailyListeningGoal', 'true');
      setCustomAccountSetting('isGamificationDailyListeningGoalSetByUser', 'false');
      logSegmentEvent('gamification_set_daily_goal', {
        dailyListeningGoalInMinutes: 2,
        source: 'web',
        from: type,
        edit: false,
        isSetAutomatically: true
      });
    }
  }, [type, store]);

  const hasDailyListeningGoal = !!selectedDailyListeningGoal;

  const onCtaClick = async () => {
    if (!selectedDailyListeningGoal) return;
    setButtonState(ButtonState.loading);
    dispatch(authActions.setHasSetDailyListeningGoal(true));
    await setCustomAccountSetting('hasSetDailyListeningGoal', 'true');
    dispatch(gamificationActions.setDailyListeningGoal(selectedDailyListeningGoal))
      .then(() => {
        setButtonState(ButtonState.success);
        logSegmentEvent('gamification_set_daily_goal', {
          dailyListeningGoalInMinutes: selectedDailyListeningGoal,
          source: 'web',
          from: type,
          edit: hasDailyListeningGoal,
          isSetAutomatically: false
        });
        if (type === 'settingsPage') {
          setTimeout(() => setButtonState(ButtonState.init), 2500);
        }
        onFinishSettingGoal && onFinishSettingGoal();
      })
      .catch(() => setButtonState(ButtonState.init));
  };

  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const title: string = useMemo(() => {
    if (hasDailyListeningGoal) {
      return t('Adjust your goal');
    } else {
      return t('Set a daily goal');
    }
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDailyListeningGoal]);

  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const subtitle: string = useMemo(() => {
    if (hasDailyListeningGoal) {
      return t('A few minutes each day is all you need to form your listening habit.');
    } else {
      return t('Set your daily goal and get your streak tracking started!');
    }
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDailyListeningGoal]);

  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const ctaText: string = useMemo(() => {
    if (hasDailyListeningGoal) {
      return t('Adjust my goal');
    } else {
      return t('Set my goal');
    }
    // ESLint: React Hook useMemo has a missing dependency: 't'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDailyListeningGoal]);

  const ctaHeightClass = useMemo<string>(() => {
    if (type === 'modal') return 'h-12';
    return 'h-[40px]';
  }, [type]);

  return (
    <div ref={ref} className={`flex flex-col items-center text-center font-ABCDiatype ${type === 'onboardingStep' ? 'checklist-subitem-wrapper' : ''}`}>
      {shouldShowTitle && (
        <div
          className={twMerge(
            type === 'modal' ? 'text-2xl' : 'text-md', //
            type === 'modal' ? 'leading-8' : 'leading-6',
            'font-bold',
            'tracking-[0.01em]',
            'text-primaryText'
          )}
        >
          {title}
        </div>
      )}
      {shouldShowSubtitle && <div className={twMerge('text-sm', 'font-normal', 'leading-5', 'text-glass-600', type === 'modal' ? 'mt-4' : '')}>{subtitle}</div>}
      <div
        className={twMerge(
          type === 'modal' && 'my-4',
          type === 'onboardingStep' && 'my-3',
          type === 'settingsPage' && 'my-[22px]',
          'flex',
          'w-full',
          'flex-row',
          'justify-center',
          'gap-1'
        )}
      >
        {POSSIBLE_DAILY_LISTENING_GOALS_IN_MINUTES.map(valueInMinutes => (
          <DailyListeningGoalOption
            key={valueInMinutes}
            valueInMinutes={valueInMinutes}
            active={selectedDailyListeningGoal === valueInMinutes}
            onClick={onStreakSelectGenerator(valueInMinutes)}
            disabled={buttonState !== ButtonState.init}
            type={type}
          />
        ))}
      </div>
      {buttonState === ButtonState.success ? (
        <div
          className={twMerge(
            ctaHeightClass,
            'mb-2',
            'flex',
            'w-full',
            'items-center',
            'justify-center',
            'gap-1',
            'rounded-lg',
            'border',
            'border-green-200',
            'bg-green-100',
            'fill-green-200',
            'text-center',
            'font-ABCDiatype',
            'font-bold',
            'text-green-400'
          )}
        >
          <CheckmarkIcon className="fill-green-400" />
          <span>{'Your daily goal has been set!'}</span>
        </div>
      ) : (
        <Button
          disabled={buttonState === ButtonState.loading || !selectedDailyListeningGoal}
          onClick={onCtaClick}
          className={twMerge(
            ctaHeightClass, // ensure success button has same height as cta button
            'flex',
            'w-full',
            'items-center',
            'justify-center',
            'align-middle',
            type === 'modal' ? 'text-lg font-bold' : '',
            type === 'onboardingStep' ? 'text-base font-bold' : ''
          )}
        >
          <div className="flex w-full items-center justify-center gap-1">
            {buttonState === ButtonState.loading && <Loading />}
            <span>{ctaText}</span>
          </div>
        </Button>
      )}
      {shouldShowHint && <SetDailyListeningGoalHint className="rounded-sm" selectedDailyListeningGoal={selectedDailyListeningGoal} type={type} />}
    </div>
  );
};

const Highlight = ({ children }: $TSFixMe) => (
  <>
    <span className="text-electric-350">{children}</span>
  </>
);

export const SetDailyListeningGoalHint = ({
  className,
  selectedDailyListeningGoal,
  type
}: {
  className?: string; //
  selectedDailyListeningGoal?: number;
  type?: DailyListeningGoalPickerProps['type'];
}) => {
  const content = useMemo(() => {
    switch (selectedDailyListeningGoal) {
      case 2:
        return (
          <>
            Listening just <Highlight>2 minutes</Highlight> a day means you would finish <Highlight>over 250 news articles</Highlight> this year!
          </>
        );
      case 5:
        return (
          <>
            The average person reads <Highlight>less than 2 books</Highlight> a year. <Highlight>Listen 5 minutes a day and double that!</Highlight>
          </>
        );
      case 10:
        return (
          <>
            Listening <Highlight>10 minutes</Highlight> a day means you could read <Highlight>20 extra books</Highlight> this year!
          </>
        );

      case 15:
      default:
        return (
          <>
            Listen <Highlight>15 minutes</Highlight> a day and you’d complete the <Highlight>complete works of Shakespeare… twice!</Highlight>
          </>
        );
    }
  }, [selectedDailyListeningGoal]);
  return (
    <div
      className={twMerge(
        'z-1 relative mt-3 w-full overflow-hidden bg-glass-200 px-4 py-2 text-left', //
        type === 'onboardingStep' ? 'text-xs' : '',
        className ?? ''
      )}
    >
      <div className="absolute -right-[25px] -top-[25px] z-0 h-[60px] w-[60px] rounded-full bg-electric-200" />
      <HintIcon className="absolute right-2 top-2 fill-electric-350" />
      <div className="mr-4 text-xs text-glass-600">{content}</div>
    </div>
  );
};
